.sidebar {
  position: fixed;
  left: 0;
  top:0;
  bottom: 0;
  width: $menu-width;
  display: flex;
  flex-direction: column;
  background: $white-color;
  box-shadow: 0 0.4rem 2.5rem 0.5rem rgba(0, 0, 0, 0.05);
  z-index: 1;

  .nav-outside {
    flex: 1;
    display: flex;
  }

  .sidebar-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 2em 0;

    img {
      width: 100%;
      max-width: 10rem
    }
  }

  .sidebar-menu {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: 0;

    .nav-item {
      display: flex;
      min-height: 4rem;
      border-top: 0.1rem solid $light-gray;

      .nav-link {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-weight: 600;
        cursor: pointer;
        font-size: $base-font-size;
        text-align: left;
        color: $base-text-color;

        &:hover {
          background: $theme-color;
          color: $white-color;
        }

        &.active {
          color: $theme-color;

          &:hover {
            color: $white-color;
            cursor: initial;
          }
        }

        .nav-link-icon {
          font-size: $m-font-size;
          padding: 1rem;
        }

        .nav-link-text {
        }
      }
    }

    .nav-spacer {
      border-top: 0.1rem solid $light-gray;
      flex: 1;
    }
  }
}

@media only screen and (max-width: $breakpoint-medium) {
  .panel-view {
    &.contestant {
      .sidebar {
        right: 0;
        top: unset;
        width: unset;

        .sidebar-logo {
          display: none;
        }
        .nav-spacer {
          display: none;
        }
        .sidebar-menu {
          flex-direction: row;

          .nav-spacer {
            display: none;
          }
          .nav-item {
            flex: 1;
            display: flex;
            justify-content: center;

            .nav-link {
              justify-content: center;

              .nav-link-text {
                display: none;
              }

            }
          }
        }
      }
    }
  }
  .sidebar {
    .nav-outside {
      .sidebar-menu {
        .nav-spacer {
          flex: 0;
        }
      }
    }
  }
}
