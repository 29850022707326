.panel-view {
  color: $base-text-color;
  background: $base-background-color;
  font-family: $base-font-family;
  min-height: 100vh;
  min-width: 146rem;
  display:flex;

  &.contestant {
    min-width: unset;
  }

  .content {
    flex: 1;
    display:flex;
    flex-direction: column;
    margin-left: $menu-width;
    padding: 2rem 4rem;
    overflow-x: hidden;
    overflow-y: auto;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-height: 4rem;
      margin: 0 0 2rem;
      padding-bottom: 1.5rem;
      text-transform: uppercase;
      font-size: $xl-font-size;
      font-weight: 300;
      border-bottom: thin solid $base-text-color;
    }

    .subheader {
      display: flex;
      justify-content: flex-start;
      font-size: $m-font-size;
      color: $grey-text-color;
      border-bottom: thin solid $table-border-color;
      padding-bottom: 1rem;
      .btn {
        font-size: $xxxs-font-size;
        line-height: 1;
      }
    }

    .navigation-tabs {
      width:100%;
      min-height: 5rem;
      display: flex;
      margin-bottom: 2rem;
      .navigation-tab {
        &-list {
          display: flex;
          flex: 1;
          justify-content: space-between;
          align-items: center;
          list-style: none;
        }
        &-item {
          display: flex;
          flex: 1;
          justify-content: center;
        }
        &-item-link {
          padding: 0.5rem;
          text-decoration: none;
          &.active {
            border-bottom: thin solid $theme-color;
            font-weight: 600;
          }
        }
      }
    }

    .fatigue-profile-alert {
      min-width: 32rem;
      min-height: 8rem;
      display: flex;
      position: fixed;
      bottom: 1rem;
      right: 1rem;
      color: $white-color;
      background-color: $danger-color;
      padding: 1rem;
      border-radius: $border-radius;
      cursor: pointer;
      z-index: 101;

      &:hover {
        opacity: 0.75;
      }

      &.hide {
        display: none;
      }

      .main-message-wrapper {
        padding: 1rem;
        font-size: $s-font-size;
        p {
          font-weight: bold;
          font-size: $base-font-size;
        }
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
      }
      .profile-alert-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        font-size: 2rem;
      }
    }

    .specialist-type-name {
      text-transform: capitalize;
      font-size: $ms-font-size;
      padding: 0.4em 0;
    }
  }
}

@media only screen and (max-width: $breakpoint-medium) {
  .panel-view {
    margin-bottom: 6rem;

    &.contestant {
      .content {
        margin-left: 0;
        padding: 1rem;
        .header {
          font-size: $ml-font-size;
        }
        .subheader {
          flex-direction: column;
          font-size: $s-font-size;

          .btn {
            margin-top: 1rem;
          }
        }
        .fatigue-profile-alert {
          bottom: 6rem;
        }
      }
    }
  }
}
@media only screen and (max-width: $breakpoint-xsmall) {
  .panel-view {
    &.contestant {
      .content {
        .fatigue-profile-alert {
          right: 0;
          left: 0;
          border-radius: 0;
        }
      }
    }
  }
}