.tooltip {
  z-index: 1460;
  .arrow {
    display: none;
  }
  .tooltip-inner {
    padding: 0.5rem 1rem;
    background-color: rgba(0,0,0,0.85);
    border-radius: $border-radius;
    max-width: unset;
    font-size: 1.6rem;
  }
}
