
.user-view-header {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;

  h2 {
    font-size: $s-font-size;
    font-weight: bold;
  }

  .user-view-close-btn {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
  }
}

.user-view-subheader {
  font-weight: bold;
  padding: 2rem;
  text-align: center;
}

.action-buttons-wrapper {
  display: flex;
  flex: 1;
  justify-content: space-between;
  margin: 1rem 0;

  .action-button {
    display: flex;
    justify-content: center;
    align-content: center;
  }
}

.user-connection-list-wrapper {
  padding: 2rem 0;

  &.new-variable-menu {
      padding: 2rem;
  }

  &.add-calendar-event {
    padding: 2rem;
    .react-datepicker__day {
      display: flex;
    }
  }

  .badge-wrapper {
    &.training-plan {
      margin-bottom: 1.5rem;
      display: flex;
    }
  }

  .no-data {
    padding: 2rem 0;
    font-size: $base-font-size;
    font-weight: 600;
  }

  .user-connection-list-header {
    text-transform: uppercase;
    font-weight: 700;
    font-size: $s-font-size;
    margin-bottom: 1rem;
    vertical-align: middle;

    &.fatigue-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .item-counter {
      font-size: $s-font-size;
      color: $light-color;
      font-weight: normal;
    }

    .add-item-menu-wrapper {
      position: relative;

      .add-item-menu-list {
        position: absolute;
        list-style: none;
        padding: 0.5rem;
        font-size: $s-font-size;
        background-color: $table-row-background-color;
        border: thin solid $table-border-color;
        text-transform: unset;
        right: 0;
        z-index: 1480;
        white-space: nowrap;

        .add-item-menu-item {
          padding: 0.5rem;
          cursor: pointer;

          &:hover {
            background-color: darken($table-row-background-color, 5%)
          }
        }
      }
    }
  }

  .add-input-form-wrapper, .add-group-form-wrapper {
    margin-top: 1.5rem;
  }

  .user-connection-list {
    list-style: none;
    margin-bottom: 0;
    max-height: 11rem;
    overflow: auto;
    margin-right: 1.5rem;

    &.long-list {
      margin-right: 0;
    }

    &.fatigue-profile {
      max-height: unset;
      margin-right: 0;

      .user-connection-list-item {
        border-bottom: thin solid $table-border-color;

        &:nth-child(2n), &:hover {
          background: $table-row-background-color;
        }

        &.no-drag {
          .drag-handle {
            display: none;
          }
        }

        .drag-handle {
          display: inline-flex;
          height: 4rem;
          width: 4rem;
          font-size: $m-font-size;
          color: $light-color;
          padding: 1rem;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }

        .badge-wrapper {
          display: inline-flex;
          padding: 0 2rem;
          flex: 1;

        }

        .profile-wrapper {
          display: flex;
          align-items: center;
          flex: 1;
          flex-wrap: wrap;

          .list-item-color {
            margin: 1rem;
            padding: 0.75rem;
            height: 1.5rem;
            width: 1.5rem;
            border-radius: $border-radius;
            border: 0.1rem solid $table-border-color;
          }
        }
      }
    }

    &:not(:hover)::-webkit-scrollbar {
      visibility: hidden;
      width: 1.5rem;
    }

    scrollbar-color: transparent transparent;
    scrollbar-width: 1.5rem;

    &:hover {
      scrollbar-color: auto;
    }

    .user-connection-list-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.5rem 1rem;
      list-style: none;
      font-size: $base-font-size;

      .list-item-type {
        color: $light-color;
      }

      .button-wrapper {
        display: flex;
      }
    }
  }
}
