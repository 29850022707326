.input-group {
  color: $black-color;

  .button-container {
    display: flex;
    width: unset;
    justify-content: flex-end;
    flex-direction: row;
  }

  .form-label {
    font-size: $base-font-size;
    padding-bottom: 0.5rem;
  }

  .error-block {
    height: 3rem;
    font-size: $xxs-font-size;
    color: $danger-color;
  }

  .disabled {
    opacity: 0.5;
  }

  .checkbox-container {
      margin: 1rem 0;
    .checkbox-wrapper {
      display: flex;
      align-items: center;

      .checkbox-input {
        margin-right: 1rem;
      }
    }
  }

  .form-control-input {
    width: 100%;
    height: 4rem;
    padding: 0 1.25rem;
    font-size: $s-font-size;
    font-weight: 400;
    line-height: 1.5;
    color: $dark-color;
    background-color: $white-color;
    border: 0.1rem solid $theme-color;
    border-radius: $border-radius;
    margin-bottom: 2rem;

    &:focus {
      border: thin solid darken($theme-color, 5%);
      box-shadow: 0 0 0 0.1rem darken($theme-color, 5%);
      background-color: $white-color;
      outline: 0;
    }

    &.has-error {
      border: 0.1rem solid $danger-color;
      margin-bottom: 0;

      &:focus {
        border: thin solid darken($danger-color, 5%);
        box-shadow: 0 0 0 0.1rem darken($danger-color, 5%);
        background-color: $white-color;
        outline: 0;
      }
    }

    &:disabled {
      background-color: $disabled-input-color;

    }

    &.currency {
      padding-left: 4rem;
      width: 100%;

      .currency-marker {
        padding: 1rem;
        left: .1rem;
        bottom: 0;
        height: 2.8rem;
        border-radius: $border-radius;
        background-color: $disabled-input-color;
        font-size: 1.6rem;
        margin: .1rem 0;
      }
    }

    &.radio {
      width: 1.4rem;
    }

    &.textarea {
      height: auto;

      &:disabled {
        background-color: $disabled-input-color;
      }
    }

    &.date {
      //background-color: $disabled-input-color;
      //border: thin solid $table-border-color;
      padding: 0.5rem 1.5rem;
      color: $theme-color;
      border: $btnBorderThickness solid $table-border-color;
      background-color: $fatigue-profile-button-background;
      margin-bottom: 0;
      font-size: $base-font-size;
      text-align: center;
      font-weight: bold;
      text-transform: capitalize;
      max-width: 23rem;
      height: unset;

      &:hover,
      &:focus,
      &:active,
      &:hover:focus,
      &:hover:active,
      &:focus:active,
      &:hover:focus:active {
        color: darken($theme-color, 5%);
        background-color: darken($fatigue-profile-button-background, 2%);
        border: $btnBorderThickness solid $table-border-color;
        box-shadow: none;
      }
    }

    &.color-picker {
      width: 4rem;
      height: 4rem;
      border: none;
      border-radius: $border-radius;
      margin: 0 1rem 2rem 1rem;
      border: thin solid $table-border-color;
      position: relative;

      .color-picker-button {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background-color: transparent;
        width: 4rem;
        appearance: none;
      }

      .color-picker-wrapper {
        position: absolute;
        top: 4.5rem;
        right: 0;

        .colorpicker-reset-color-button {
          position: absolute;
          bottom: 1.1rem;
          right: 1.2rem;
          width: 2rem;
          height: 2rem;
          background: transparent;
          z-index: 3;
          border-radius: 0;

          &:before {
            content: 'X';
          }
        }

        // TODO: when creator of colorpicker update it change that:
        .block-picker {
          div {
            div {
              z-index: 2;

              span {
                div {
                  margin-right: 0.5rem !important;
                  margin-bottom: 0.5rem !important;
                  z-index: 1;
                }
              }
            }
          }
        }
      }
    }
  }

  &.has-error {
    .form-control {
      border: 0.1rem solid $danger-color;
    }
  }
}

.react-select {
  div {
    z-index: 1050;
  }
}

.form-select-input {
  .basic-single {
    width: 100%;

    .select {
      &__control {
        width: 100%;
        height: 4rem;
        font-size: $s-font-size;
        font-weight: 400;
        line-height: 1.5;
        background-color: $white-color;
        background-clip: padding-box;
        border: thin solid $theme-color;
        border-radius: $border-radius;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        margin-bottom: 0.25rem;
      }
    }
  }
}

.button-group-wrapper {
  display: flex;
  justify-content: flex-end;

  .input-group {
    width: unset;
  }
}

.form-group-wrapper {
  .error-block {
    font-size: $xxs-font-size;
    color: $danger-color;
  }
}

::-webkit-input-placeholder {
  color: $grey-text-color;
}

::-moz-placeholder {
  color: $grey-text-color;
}

:-ms-input-placeholder {
  color: $grey-text-color;
}

:-moz-placeholder {
  color: $grey-text-color;
}


.fatigue-profile-calendar-modal {
  .default-control-styles.filled-field {
    background-color: unset !important;
  }
}

.default-control-styles.filled-field {
  background-color: rgb(200, 230, 200) !important;
}

@supports (-webkit-touch-callout: none) {
  .input-group {
    .form-control-input {
      font-size: 16px !important;
    }
  }
}
