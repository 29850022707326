.pb-20 {
  padding-bottom: 2rem;
}

.mt-20 {
  padding-top: 2rem;
}

.separator {
  height: 1px;
  background-color: $table-border-color;
  width: 100%;
}

.outlined-box {
  padding: 0.5rem;
  background: rgba(0, 0, 0, 0.075);
  border-radius: $border-radius;
  margin: 0 0.5rem;
  color: $theme-color;
  letter-spacing: 0.2rem;
}

.ellipsis-host {
  display: flex;
  min-width: 0;
  flex: 1;

  .ellipsis-item {
    flex: 1;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.drag-handle {
  display: inline-flex;
  height: 4rem;
  width: 4rem;
  font-size: $m-font-size;
  color: $light-color;
  padding: 1rem;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.profile-wrapper {
  display: flex;
  align-items: center;
  flex: 1;

  .list-item-color {
    margin: 1rem;
    height: 1.5rem;
    width: 1.5rem;
    border: 0.1rem solid $table-border-color;
    border-radius: $border-radius;
  }
}

.drag-and-drop-list {
  list-style: none;
  margin-bottom: 0;
  overflow: auto;

  &.calendar-groups-list {
    max-height: 25.5rem;
  }

  &.events-list {
    max-height: 15.3rem;
  }

  &.form-groups-list {
    max-height: 50rem;
  }

  .drag-and-drop-list-item {
    border-bottom: thin solid $table-border-color;
    font-size: 1em;


    &.fatigue-group-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.5rem 1rem;
      list-style: none;
      font-size: 1em;

      &:focus {
        outline: $table-border-color auto medium;
      }

      .event-name {
        display: flex;
        flex: 1;
      }

      .event-duration {
        font-size: $s-font-size;
        flex: 1;
        margin-left: 1rem;
      }

      .list-item-type {
        color: $light-color;
      }
    }

    &:nth-child(2n), &:hover {
      background: $table-row-background-color;
    }
  }
}


.react-grid-layout {
  height: auto !important;
  width: 100%;

  .react-grid-item {
    width: 100% !important;
    height: auto !important;
    position: relative !important;
    transform: none !important;
  }
}

.react-datepicker-host {
  width: 100%;

  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker-popper:not(.timepicker-only) {
    width: 300px;

    .react-datepicker__current-month {
      justify-content: center;
      font-size: $ml-font-size;
    }
  }


}

.drag-handle {
  display: inline-flex;
  height: 4rem;
  width: 4rem;
  font-size: $m-font-size;
  color: $light-color;
  padding: 1rem;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.profile-wrapper {
  display: flex;
  align-items: center;

  .list-item-color {
    margin: 1rem;
    padding: 0.75rem;
    height: 1.5rem;
    width: 1.5rem;
    border: 0.1rem solid $table-border-color;
    border-radius: $border-radius;
  }
}

.drag-and-drop-list {
  list-style: none;
  margin-bottom: 0;
  overflow: auto;

  .drag-and-drop-list-item {
    border-bottom: thin solid $table-border-color;
    font-size: 1em;


    &.fatigue-group-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.5rem 1rem;
      list-style: none;
      font-size: 1em;

      &:focus {
        outline: $table-border-color auto medium;
      }

      .list-item-type {
        color: $light-color;
      }
    }

    &:nth-child(2n), &:hover {
      background: $table-row-background-color;
    }
  }
}

.edit-profile-image-host {
  display: flex;

  &.visible {
  flex: 1;
  width: 100%;
  }
.rs-uploader-file-items {
  display: flex;
  flex: 1;
  .rs-uploader-file-item  {
    display: flex;
    flex: 1;
    .rs-uploader-file-item-panel {
      display: flex;
      flex: 1;
      .rs-uploader-file-item-content {
        display: flex;
        flex: 1;
      }
    }
    .rs-uploader-file-item-preview {
      display: flex;
      flex: 1;
      max-width: 10rem;
      img {
        display: flex;
        flex: 1;
        width: 100%;
        object-fit: scale-down;
      }
    }
  }
}
  .rs-uploader-trigger-customize {
    display: flex;
    position: absolute;
    bottom: -3rem;
    right: 0;
    left: 0;

    input {
      display: none;
    }
    .rs-uploader-trigger-btn {
      display:  flex;
      flex: 1;
      justify-content: center;;
      padding: 0.5rem;
      background-color:  $light-gray;
      border-radius: $border-radius;
      cursor: pointer;
    }
  }
}
.edit-profile-image-accept-button {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 0.5rem;
  background-color: $success-color;
  border-radius: $border-radius;
  opacity: 0.75;
  &:hover {
    opacity: 1;
  }
}
.edit-profile-image-remove-button {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0.5rem;
  background-color: $danger-color;
  border-radius: $border-radius;
  opacity: 0.75;
  &:hover {
    opacity: 1;
  }
}
