.custom-popover {
  position: relative;
  flex: 1;
  display: flex;
  align-items: center;

  > div:first-child {
    display: flex;
    align-items: center;
  }

  .popover {
    top: 7rem;
    margin: 0;
    min-width: 20rem;
    max-width: unset;
    background-color: $white-color;
    z-index: 15;
    box-shadow: 0 0.4rem 0.4rem rgba(0, 0, 0, 0.25);
    border-radius: $border-radius;
    border: none;

    &.fade {
      opacity: 1;
    }

    .arrow {
      display: none;
    }

    .popover-body {
      padding: 0;
    }
  }
}

// move
.icon-tablet {
  transform: rotate(90deg);
}

.outlined-box {
  padding: 0.5rem 1rem;
  background: rgba(0,0,0,0.075);
  border-radius: $border-radius;
  margin: 0 0.5rem;
  color: $theme-color;
  letter-spacing: 0.2rem;
}