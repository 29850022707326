@import "./variables";
@import "./mixins";
@import "./../theme";


.react-datepicker-popper {
  .react-datepicker__navigation--next, .react-datepicker__navigation--previous {
    &:hover {
      background: unset!important;
    }
  }
}

.month-calendar {
  .form-label {
    display: none;
  }

  .react-datepicker {
    font-family: $datepicker__font-family;
    background-color: #fff;
    color: $datepicker__text-color;
    border: none;
    display: inline-block;
    position: relative;
    width: 100%;
    font-size: $base-font-size;

    .react-datepicker__triangle {
      display: none;
    }

    .react-datepicker__navigation {
      //height: 4rem;
      //width: 4rem;
      //text-align: center;
      //cursor: pointer;
      //position: absolute;
      //top: 0;
      //padding: 0;
      //border: none;
      //border-radius: $border-radius;
      //z-index: 1;
      //text-indent: -999em;
      //overflow: hidden;
      //
      //&--previous {
      //  left: 0;
      //  background: #ebebeb url("../../images/arrow-left.png") no-repeat center;
      //
      //  &:hover {
      //    border-right-color: unset;
      //    background: #ebebeb url("../../images/arrow-left-active.png") no-repeat center !important;
      //  }
      //}
      //
      //&--next {
      //  right: 0;
      //  background: #ebebeb url("../../images/arrow-right.png") no-repeat center;
      //
      //  &:hover {
      //    border-left-color: unset !important;
      //    background: #ebebeb url("../../images/arrow-right-active.png") no-repeat center !important;
      //  }
      //}

      &:hover {
        background-color: $theme-color;
      }
      &:focus, &:hover {
        border: 0.45rem solid transparent;
        &.react-datepicker__navigation--next {
          border-left-color: $datepicker__muted-color;
        }

        &.react-datepicker__navigation--previous {
          border-right-color: $datepicker__muted-color;
        }
      }
    }

    .react-datepicker__header {
      text-align: center;
      position: relative;
      display: flex;
      flex-direction: column;
      border: none;
      background-color: unset;
      padding-top: 0.5rem;

      .react-datepicker__header__dropdown {
        display: none;
      }

      .react-datepicker__current-month {
        align-self: center;
        font-size: $m-font-size;
        font-weight: 400;
        padding: .375rem 1.5rem 2rem;
      }

      .react-datepicker__day-names {
        border-radius: $border-radius $border-radius 0 0;
      }
    }

    .react-datepicker__month {
      margin: 0;
      padding: 1rem 0;
      border-radius: 0 0 $border-radius $border-radius;

      .react-datepicker__day {
        font-size: $base-font-size;
        line-height: 5rem;
        display: flex;
        flex: unset;
        justify-content: center;
        align-items: center;
        outline: none;
        margin: 0.75rem 0.5rem;
        height: 2.4em;
        width: 2.4em;
        border-radius: 0.5em;
        color: $white-color;
        position: relative;

        &.complete {
          background-color: $success-color;

          &:after {
            content: '\e83e';
          }
        }

        &.incomplete {
          background-color: $danger-color;

          &:after {
            content: '\e81c';
          }
        }

        //trick ukrywający naturalne i niezmienialne działanie react-datepickera
        &.unavailable.react-datepicker__day--selected {
          background-color: transparent;
        }

        &.incomplete.react-datepicker__day--disabled.react-datepicker__day--selected {
          background-color: $danger-color;
        }

        &.complete, &.incomplete {
          &.react-datepicker__day--selected {
            background-color: $theme-color;

          }
          &:after {
            font-family: 'feather';
            position: absolute;
            top: 0.3rem;
            right: 0.25rem;
            font-size: 0.6em;
            line-height: 1;
          }
        }
//custom pod ukrycie dni z innych miesięcy w kalendarzu
        &.react-datepicker__day--outside-month {
          color: #ccc;
          pointer-events: none;
          opacity: 0.0;
          &.react-datepicker__day.incomplete, &.react-datepicker__day.complete, &.react-datepicker__day--disabled  {
            background-color: transparent;
            opacity: 0.0;
          }
        }

        &.react-datepicker__day--today {
          background-color: $warning-color;

          &.react-datepicker__day--selected {
            background-color: $theme-color;
          }
        }

        &.react-datepicker__day--disabled {
          opacity: 0.5;
        }

        &.unavailable {
          color: $datepicker-disabled-color;
        }

        &:hover {
          opacity: 0.5;
          border-radius: 0.5em;
        }
        &:not(.unavailable):hover {
          cursor: pointer;
        }
        //trick ukrywający naturalne i niezmienialne działanie react-datepickera
        &.react-datepicker__day--keyboard-selected:not(.react-datepicker__day--today):not(.incomplete):not(.complete) {
         background-color: transparent;
        }
      }
    }
  }
}

@media only screen and (max-width: $breakpoint-medium) {
  .month-calendar {
    .react-datepicker {
      .react-datepicker__header {
        .react-datepicker__day-names {
          .react-datepicker__day-name {
            font-size: $base-font-size;
          }
        }
      }
    }
  }
}
