.basic-form-control-wrapper {
  padding-bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: $black-color;


  &.label-hidden {
    justify-content: flex-end;
  }

  label {
    font-size: 1.5rem;
    &.labelHidden {
      display: none;
    }
  }
  &.has-error {
    padding-bottom: 0;
  }

  .error-block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: $danger-color;
    height: 3rem;
    font-size: 1rem;
  }

  .basic-form-control {
    display: block;
    width: 100%;
    height: 4rem;
    padding: 1.6rem 1.9rem 1.3rem 1.9rem;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.5;
    background-color: $white-color;
    background-clip: padding-box;
    border: thin solid $theme-color;
    border-radius: $border-radius;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    margin-bottom: 0.25rem;

    &:focus {
      border: thin solid darken($theme-color, 5%);
      box-shadow: 0 0 0 0.1rem darken($theme-color, 5%);
      background-color: $white-color;
      outline: 0;
    }

    &.has-error {
      border: thin solid $danger-color;
      box-shadow: none;
      &:focus {
        border: thin solid darken($danger-color, 5%);
        box-shadow: 0 0 0 0.1rem darken($danger-color, 5%);
        background-color: $white-color;
        outline: 0;
      }
    }

    &:disabled {
      background-color: rgb(242, 242, 242);
      cursor: not-allowed;
    }
  }
}


