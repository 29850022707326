@import "./variables";
@import "./mixins";

.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
}

.react-datepicker {
  font-family: $datepicker__font-family;
  font-size: $datepicker__font-size;
  background-color: #fff;
  color: $datepicker__text-color;
  //border: 1px solid $datepicker__border-color;
  //border-radius: $datepicker__border-radius;
  display: inline-block;
  position: relative;
  width: 100%;
}

.react-datepicker--time-only {
  .react-datepicker__triangle {
    left: 35px;
  }

  .react-datepicker__time-container {
    border-left: 0;
  }

  .react-datepicker__time {
    border-radius: 0.3rem;
  }

  .react-datepicker__time-box {
    border-radius: 0.3rem;
  }
}

.react-datepicker__triangle {
  position: absolute;
  left: 50px;
}

.react-datepicker-popper {
  z-index: 1;

  &[data-placement^="bottom"] {
    margin-top: $datepicker__triangle-size + 2px;

    .react-datepicker__triangle {
      @extend %triangle-arrow-up;
    }
  }

  &[data-placement="bottom-end"],
  &[data-placement="top-end"] {
    .react-datepicker__triangle {
      left: auto;
      right: 50px;
    }
  }

  &[data-placement^="top"] {
    margin-bottom: $datepicker__triangle-size + 2px;

    .react-datepicker__triangle {
      @extend %triangle-arrow-down;
    }
  }

  &[data-placement^="right"] {
    margin-left: $datepicker__triangle-size;

    .react-datepicker__triangle {
      left: auto;
      right: 42px;
    }
  }

  &[data-placement^="left"] {
    margin-right: $datepicker__triangle-size;

    .react-datepicker__triangle {
      left: 42px;
      right: auto;
    }
  }
}

.react-datepicker__header {
  text-align: center;
  //background-color: $datepicker__background-color;
  //border-bottom: 1px solid $datepicker__border-color;
  border-top-left-radius: $datepicker__border-radius;
  border-top-right-radius: $datepicker__border-radius;
  padding-top: 8px;
  position: relative;
  display: flex;
  flex-direction: column;

  &--time {
    padding-bottom: 8px;
    padding-left: 5px;
    padding-right: 5px;
  }
}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
  display: inline-block;
  margin: 0 2px;
}


.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  // display: none;
  display: flex;
  align-items: center;
  // align-self: flex-end;
  -webkit-appearance: none;
  box-sizing: border-box;
  // border: thin solid #d2d8dd;
  clear: both;
  cursor: pointer;
  font-family: inherit;
  font-size: 1.6rem;
  font-weight: 400;
  // min-height: 4.4rem;
  outline: none;
  padding: .375rem 1.5rem;
  position: relative;
  transition: all .2s ease-in-out;
  -webkit-user-select: none;
  user-select: none;
  white-space: nowrap;
  color: #6c757d;
  border-radius: 0;
  margin-top: 0.5rem;
  // margin-bottom: 2rem;
  //margin-top: 0;
  //color: $datepicker__header-color;
  //font-weight: bold;
  //font-size: $datepicker__font-size * 1.18;
}

.react-datepicker-time__header {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.react-datepicker__navigation {
  //line-height: 4rem;
  //height: 4rem;
  //width: 4rem;
  //text-align: center;
  //cursor: pointer;
  //position: absolute;
  //top: 50%;
  // width: 0;
  //padding: 0;
 // border: $datepicker__navigation-size solid transparent;
 // z-index: 1;
  // height: 10px;
  // width: 10px;
  //text-indent: -999em;
  //overflow: hidden;

  &--previous {
   // left: -25px;
   //// border-right-color: $datepicker__muted-color;
   // background: #EBEBEB url("../../images/arrow-left.png") no-repeat center;

    &:hover {
      border-right-color: darken($datepicker__muted-color, 10%);
      background: #ebebeb url("../../images/arrow-left-active.png") no-repeat center;
    }

    &--disabled,
    &--disabled:hover {
      border-right-color: $datepicker__navigation-disabled-color;
      cursor: default;
    }
  }

  &--next {
    //right: -25px;
    //background: #ebebeb url("../../images/arrow-right.png") no-repeat center;
    // border-left-color: $datepicker__muted-color;
    &--with-time:not(&--with-today-button) {
      right: 80px;
    }

    &:hover {
      border-left-color: darken($datepicker__muted-color, 10%);
      background: #ebebeb url("../../images/arrow-right-active.png") no-repeat center;
    }

    &--disabled,
    &--disabled:hover {
      border-left-color: $datepicker__navigation-disabled-color;
      cursor: default;
    }
  }

  &--years {
    position: relative;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;

    &-previous {
      top: 4px;
      border-top-color: $datepicker__muted-color;

      &:hover {
        border-top-color: darken($datepicker__muted-color, 10%);
      }
    }

    &-upcoming {
      top: -4px;
      border-bottom-color: $datepicker__muted-color;

      &:hover {
        border-bottom-color: darken($datepicker__muted-color, 10%);
      }
    }
  }

  &:hover {
    background-color: $theme-color;
  }
}

.react-datepicker.react-date-and-time {
  .react-datepicker__month-container {
    width: calc(100% - 85px)
  }
}

.react-datepicker__month-container {
  float: left;
  width: 100%;
  .react-datepicker__month {
    margin: 0;
    padding-top: 0.5rem;
  }
}

.react-datepicker__year {
  margin: $datepicker__margin;
  text-align: center;
  &-wrapper {
    display: flex;
    flex-wrap: wrap;
    max-width: 180px;
  }
  .react-datepicker__year-text {
    display: inline-block;
    width: 4rem;
    margin: 2px;
  }
}

.react-datepicker__month {
  // margin: $datepicker__margin;
  background-color: #F9F9F9;
  text-align: center;
  width: 100%;

  .react-datepicker__month-text,
  .react-datepicker__quarter-text {
    display: inline-block;
    width: 4rem;
    margin: 2px;
  }
}

.react-datepicker__input-time-container {
  clear: both;
  width: 100%;
  float: left;
  margin: 5px 0 10px 15px;
  text-align: left;
  .react-datepicker-time__caption {
    display: inline-block;
  }
  .react-datepicker-time__input-container {
    display: inline-block;
    .react-datepicker-time__input {
      display: inline-block;
      margin-left: 10px;
      input {
        width: 85px;
      }
      input[type="time"]::-webkit-inner-spin-button,
      input[type="time"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      input[type="time"] {
        -moz-appearance: textfield;
      }
    }
    .react-datepicker-time__delimiter {
      margin-left: 5px;
      display: inline-block;
    }
  }
}

.react-datepicker__time-container {
  float: right;
  border-left: 1px solid $datepicker__border-color;
  width: 85px;

  &--with-today-button {
    display: inline;
    border: 1px solid #aeaeae;
    border-radius: 0.3rem;
    position: absolute;
    right: -72px;
    top: 0;
  }

  .react-datepicker__time {
    position: relative;
    background: white;
    border-bottom-right-radius: 0.3rem;

    .react-datepicker__time-box {
      width: 85px;
      overflow-x: hidden;
      margin: 0 auto;
      text-align: center;
      ul.react-datepicker__time-list {
        list-style: none;
        margin: 0;
        height: calc(195px + (#{$datepicker__item-size} / 2));
        overflow-y: scroll;
        padding-right: 0px;
        padding-left: 0px;
        width: 100%;
        box-sizing: content-box;

        li.react-datepicker__time-list-item {
          height: 30px;
          padding: 5px 10px;
          white-space: nowrap;
          &:hover {
            cursor: pointer;
            background-color: $datepicker__background-color;
          }
          &--selected {
            background-color: $datepicker__selected-color;
            color: white;
            font-weight: bold;
            &:hover {
              background-color: $datepicker__selected-color;
            }
          }
          &--disabled {
            color: $datepicker__muted-color;

            &:hover {
              cursor: default;
              background-color: transparent;
            }
          }
        }
      }
    }
  }
}

.react-datepicker__week-number {
  color: $datepicker__muted-color;
  display: inline-block;
  width: $datepicker__item-size;
  line-height: $datepicker__item-size;
  text-align: center;
  margin: $datepicker__day-margin;
  &.react-datepicker__week-number--clickable {
    cursor: pointer;
    &:hover {
      border-radius: $datepicker__border-radius;
      background-color: $datepicker__background-color;
    }
  }
}

.react-datepicker__day-names,
.react-datepicker__week {
  white-space: nowrap;
}

.react-datepicker__week {
  display: flex;
  justify-content: space-around;
}

.react-datepicker__day-names {
  background-color: $datepicker__background-color;
  display: flex;
  .react-datepicker__day-name {

   // width: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    line-height: 2rem;
    margin: 1rem 0;
    border-right: thin solid $border-color;
    font-weight: 600;
    font-size: $m-font-size;

    &:last-of-type {
      border-right: none;
    }
  }
}


.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: $datepicker__text-color;
  display: flex;
  width: $datepicker__item-size;
  line-height: $datepicker__item-size;
  text-align: center;
  margin: $datepicker__day-margin;
}

.react-datepicker__month,
.react-datepicker__quarter {
  &--selected,
  &--in-selecting-range,
  &--in-range {
    border-radius: $datepicker__border-radius;
    background-color: $datepicker__selected-color;
    color: #fff;
    font-weight: bold;

    &:hover {
      background-color: darken($datepicker__selected-color, 5%);
    }
  }
  &--disabled {
    color: $datepicker__muted-color;
    pointer-events: none;
    &:hover {
      cursor: default;
      background-color: transparent;
    }
  }
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #000;
  display: flex;
  width: unset;
  text-align: center;
  margin: unset;
  flex: 1;
}


.react-datepicker__day {
  font-size: $m-font-size;
  line-height: 5rem;
 // width: 5rem;
  display: flex;
  // flex: 1;
  height: 3rem;
  width: 3rem;
  justify-content: center;
  align-items: center;
  outline: none;

  &.available {
    color: #35E4A1;
    background-color: #E0F7EF;
    font-weight: 600;
    border-radius: 50%;

    &.react-datepicker__day--selected {
      color: $white-color;
      background-color: $theme-color;
      outline: none;
    }

    &.react-datepicker__day--disabled {
      color: #ccc;
      background-color: transparent;
      font-weight: 400;
    }
  }
}

.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text,
.react-datepicker__year-text {
  cursor: pointer;

  &:hover {
    //border-radius: 50%;
    background-color: $datepicker__background-color;
  }

  //&--today {
  //  font-weight: bold;
  //}

  &--selected,
  &--in-selecting-range,
  &--in-range {
    //border-radius: 50%;
    background-color: $theme-color;
    color: #fff;
    font-weight: bold;

    &:hover {
      background-color: darken($theme-color, 5%);
    }
  }

  &--keyboard-selected {
    //border-radius: 50%;
    background-color: $theme-color;
    color: #fff;
    font-weight: 600;

    &:hover {
      background-color: darken($theme-color, 5%);
    }
  }

  &--in-selecting-range:not(&--in-range) {
    background-color: rgba($theme-color, 0.5);
  }

  &--in-range:not(&--in-selecting-range) {
    .react-datepicker__month--selecting-range & {
      background-color: $datepicker__background-color;
      color: $datepicker__text-color;
    }
  }

  &--disabled {
    cursor: default;
    color: $datepicker__muted-color;

    &:hover {
      background-color: transparent;
    }
  }
}

.react-datepicker__month-text,
.react-datepicker__quarter-text {
  &.react-datepicker__month--selected,
  &.react-datepicker__month--in-range,
  &.react-datepicker__quarter--selected,
  &.react-datepicker__quarter--in-range {
    &:hover {
      background-color: $theme-color;
    }
  }
  &:hover {
    background-color: $datepicker__background-color;
  }
}

.react-datepicker__input-container {
  position: relative;
  display: inline-block;
  width: 100%;
}

.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view {
  border: 1px solid transparent;
  border-radius: $datepicker__border-radius;

  &:hover {
    cursor: pointer;

    .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-read-view--down-arrow {
      border-top-color: darken($datepicker__muted-color, 10%);
    }
  }

  &--down-arrow {
    @extend %triangle-arrow-down;
    border-top-color: $datepicker__muted-color;
    float: right;
    margin-left: 20px;
    top: 8px;
    position: relative;
    border-width: $datepicker__navigation-size;
  }
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
  background-color: $datepicker__background-color;
  position: absolute;
  width: 50%;
  left: 25%;
  top: 30px;
  z-index: 1;
  text-align: center;
  border-radius: $datepicker__border-radius;
  border: 1px solid $datepicker__border-color;

  &:hover {
    cursor: pointer;
  }

  &--scrollable {
    height: 150px;
    overflow-y: scroll;
  }
}

.react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker__month-year-option {
  line-height: 20px;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;

  &:first-of-type {
    border-top-left-radius: $datepicker__border-radius;
    border-top-right-radius: $datepicker__border-radius;
  }

  &:last-of-type {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-bottom-left-radius: $datepicker__border-radius;
    border-bottom-right-radius: $datepicker__border-radius;
  }

  &:hover {
    background-color: $datepicker__muted-color;

    .react-datepicker__navigation--years-upcoming {
      border-bottom-color: darken($datepicker__muted-color, 10%);
    }

    .react-datepicker__navigation--years-previous {
      border-top-color: darken($datepicker__muted-color, 10%);
    }
  }

  &--selected {
    position: absolute;
    left: 15px;
  }
}

.react-datepicker__close-icon {
  cursor: pointer;
  background-color: transparent;
  border: 0;
  outline: 0;
  padding: 0px 6px 0px 0px;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: table-cell;
  vertical-align: middle;

  &::after {
    cursor: pointer;
    background-color: $datepicker__selected-color;
    color: #fff;
    border-radius: 50%;
    height: 16px;
    width: 16px;
    padding: 2px;
    font-size: 12px;
    line-height: 1;
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    content: "\00d7";
  }
}

.react-datepicker__today-button {
  background: $datepicker__background-color;
  border-top: 1px solid $datepicker__border-color;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  padding: 5px 0;
  clear: left;
}

.react-datepicker__portal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  left: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 2147483647;

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    width: 3rem;
    line-height: 3rem;
  }

  // Resize for small screens
  @media (max-width: 400px), (max-height: 550px) {
    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
      width: 2rem;
      line-height: 2rem;
    }
  }

  .react-datepicker__current-month,
  .react-datepicker-time__header {
    font-size: $datepicker__font-size * 1.8;
  }

  .react-datepicker__navigation {
    border: 1.8 * $datepicker__navigation-size solid transparent;
  }

  .react-datepicker__navigation--previous {
    border-right-color: $datepicker__muted-color;

    //&:hover {
    //  border-right-color: darken($datepicker__muted-color, 10%);
    //}

    //&--disabled,
    //&--disabled:hover {
    //  border-right-color: $datepicker__navigation-disabled-color;
    //  cursor: default;
    //}
  }

  .react-datepicker__navigation--next {
    border-left-color: $datepicker__muted-color;

    //&:hover {
    //  border-left-color: darken($datepicker__muted-color, 10%);
    //}

    //&--disabled,
    //&--disabled:hover {
    //  border-left-color: $datepicker__navigation-disabled-color;
    //  cursor: default;
    //}
  }
}

.react-datepicker__header__dropdown {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0.5rem;

  .react-datepicker__month-dropdown-container {
    display: flex;
    align-items: center;
    -webkit-appearance: none;
    box-sizing: border-box;
    -webkit-appearance: none;
    border: thin solid $border-color;
    clear: both;
    cursor: pointer;
    float: left;
    font-family: inherit;
    font-size: $m-font-size;
    font-weight: 400;
    min-height: 4.4rem;
    outline: none;
    padding: .375rem 1.5rem;
    position: relative;
    transition: all .2s ease-in-out;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    white-space: nowrap;
    color: $dark-color;
    border-radius: 0;

    .react-datepicker__month-dropdown {
      top: 5rem;
      left: 0;
      width: 100%;
    }

    .react-datepicker__month-read-view--down-arrow {
      border: none;
      background: url("../../images/arrow-down.png") no-repeat center;
      top: 0;
      width: 2rem;
      height: 2rem;
    }
  }
}
.month-select-datepicker{
  .react-datepicker-host {
    .react-datepicker__tab-loop {
      .react-datepicker-popper {
        transform: none;
        top: 0;
        left: 0;
        right: 0;
        .react-datepicker {
          background-color: $fatigue-profile-button-background;
          border: thin solid $fatigue-profile-datepicker-border;
          color: $theme-color;
          font-size: $base-font-size;
          &__navigation {
            border-style: solid;
            border-width: 0.1em 0.1em 0 0;
            border-color: $theme-color;
            content: '';
            display: inline-block;
            height: 1rem;
            position: absolute;
            top: 1.5rem;
            transform: rotate(-45deg);
            vertical-align: top;
            width: 1rem;
            &:hover {
              background-color:  unset;
            }
            &--previous {
              left: 1.5rem;
              transform: rotate(-135deg);
              border-right-color: unset;
              &:hover {
                background:  unset;
              }
            }
            &--next {
              right: 1.5rem;
              transform: rotate(45deg);
              &:hover {
                background:  unset;
              }
            }
          }
          &__month-container {
            .react-datepicker__header {
              background-color: unset;
              border: none;
            }
          }
          &__month {
            margin: 0;
            padding: 1rem;
            &-text {
              padding: 0.5rem;
              font-size: $xxxs-font-size;
              width: unset;
              display: inline-flex;
              flex: 1;
              justify-content: center;
              align-items: center;
            }
            &-wrapper {
              display: flex;
              justify-content: space-evenly;
            }
          }
          &-year-header {
            color: $theme-color;
            font-size: $base-font-size;
          }
        }
      }
    }
    .react-datepicker-wrapper {

      .react-datepicker__input-container {
        input {
          cursor: pointer;
          appearance: none;
          color: transparent;
          text-shadow: 0px 0px 0px $theme-color;
          &:focus {
            color: transparent;
            text-shadow: 0px 0px 0px $theme-color;
            border: thin solid darken($table-border-color, 2%);
          }
        }
      }
    }
  }

}

