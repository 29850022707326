@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;700&display=swap');

$menu-width: 15em;
$button-height: 3.5rem;
$border-radius: 0.35rem;

// default colors
$primary-color: #635fff;
$secondary-color: #748892;
$warning-color: #b69329;
$danger-color: #dc3545;
$success-color: #58bf5f;
$info-color: #17a2b8;
$light-color: #BDBDBD;
$dark-color: #575057;

//theme
$theme-color: #327da8; //#512DA8;

// base
$base-text-color: #3a3a3a;
$base-background-color: #f8f8f8;
$base-font-family: 'Lato', sans-serif;
$input-border-color: $light-color;
$datepicker-disabled-color: #ccc;
$disabled-input-color: #F2F2F2;

// table
$table-border-color: #e2e5e8;
$table-head-background-color: #f6f7fb;
$table-row-background-color: #f9fbff;

// fatigue profile colors
$fatigue-profile-button-background: #f5f7fb;
$fatigue-profile-datepicker-border: #bfdeff;
$fatigue-profile-table-border: #e1e4e8;
$fatigue-calendar-variable-badge-text: #9e9e9e;
$list-button-background-color: #327da80d;
$fatigue-profile-accordion-selected-color: #F4F4F4;

//secondary
$secondary-font-family: 'Nunito', sans-serif;

// basic colors:
$white-color: #fff;
$black-color: #000;
$light-gray: #eee;
$grey-text-color: #7c7c8d;

// breakpoints:
$breakpoint-xsmall: 480px;
$breakpoint-small: 768px;
$breakpoint-medium: 992px;
$breakpoint-large: 1024px;
$breakpoint-xlarge: 1280px;
$breakpoint-xxlarge: 1400px;
$breakpoint-full: 1600px;

$base-font-size: 1em;
$s-font-size: 0.875em; // 1.4rem
$xs-font-size: 0.75em; // 1.2rem
$xxs-font-size: 0.625em; // 1.0rem
$xxxs-font-size: 0.5em; // 0.8rem

$ms-font-size: 1.125em; // 1.8rem
$m-font-size: 1.25em; // 2rem
$ml-font-size: 1.375em; // 2.2rem
$l-font-size: 1.625em; // 2.6rem
$xl-font-size: 1.875em; // 3.0rem


