.custom-modal-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1040;

  &.add-calendar-event-modal {
    .custom-modal {
      min-width: 50vw;
      min-height: 55vh;
      .custom-modal-body {
        overflow: unset;
      }
    }
  }
  &.profile-modal {
    .custom-modal {
      .custom-modal-body {
        padding-top: 0;
      }
    }
  }
  &.fatigue-profile-calendar-modal {
    .custom-modal {
      display: flex;
      flex-direction: column;
      height: 95vh;
      width: 95vw;

      .custom-modal-body {
        flex: 1;
        display: flex;
      }
    }
  }
  &.assign-profile-modal {
    .custom-modal {
      min-height: unset;
    }
  }
  &.fatigue-calendar-edit-input-modal {
    .custom-modal {
      height: 95vh;
      width: 95vw;
    }
  }
  &.fatigue-profile-variables-modal {
    .custom-modal {
      height: 95vh;
      width: 95vw;
      .loader-container {
        z-index: 10001;
      }
    }
    .fatigue-variables-header {
      max-width: 90%;
    }
  }
  &.add-input-modal {
    .custom-modal {
        .custom-modal-body {
            overflow: visible;
        }
    }
  }
  &.fatigue-profile-settings-modal {
    .custom-modal {
      height: 95vh;
      width: 95vw;
      background-color: $base-background-color;
      position: relative;
      .delete-group-modal, .add-input-modal {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        .custom-modal {
          height: unset;
          width: unset;
          box-shadow: .5rem .5rem 1.9rem -.6rem rgba(0,0,0,0.48);
        }
      }
      .add-input-modal {
        background-color: transparent;
        .custom-modal {
          height: 100%;
          width: 100%;
          .custom-modal-body {
            height: 90%;
            .custom-card {
              padding: 2rem;
              box-shadow: none;
              .form-control-input {
                &.color-picker {
                  margin: 0 0 2rem 0;
                }
              }
            }
          }
        }
      }
      .custom-modal-header {
        .profile-settings-header {
          display: block;
          text-transform: capitalize;
          .profile-name {
            font-weight: 300;
          }
        }

      }
      .custom-modal-body {
        .profile-settings-body {
          min-height: 75vh;
        }
      }
    }
  }

  .custom-modal {
    position: relative;
    padding: 1rem;
    background-color: $white-color;
    border-radius: $border-radius;
    min-width: 48rem;
    min-height: 61.1rem;

    .custom-modal-header {
      padding: 2rem;
      font-size: $ml-font-size;
      font-weight: 600;
    }

    .custom-modal-body {
      max-height: 80vh;
      overflow: auto;
      padding: 2rem;
      font-size: $base-font-size;

      .custom-modal-warning {
        color: $danger-color;
        padding: 0 0 2rem 0;
      }

      .custom-modal-form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: stretch;
        margin: 2rem 0;

        .custom-modal-form-label {
          display: flex;
          flex-direction: column;

          .custom-modal-form-input {
            margin-top: .5rem;
            padding: .5rem 1rem;
            margin-bottom: 2.2rem;

            &:invalid {
              margin-bottom: 0;
              border-color: $danger-color;
            }
          }

          .custom-modal-error-message {
            color: $danger-color;
            font-size: 1.2rem;
          }
        }
      }
    }

    .custom-modal-footer {
      padding: 2rem;
      display: flex;
      justify-content: flex-end;

      .btn {
        margin-left: 2rem;
      }
    }

    .custom-modal-close {
      position: absolute;
      top: 2rem;
      right: 2.1rem;
      width: 3.6rem;
      height: 3.6rem;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: $m-font-size;
      border-radius: 50%;
      cursor: pointer;

      &:hover {
        background: rgba(0, 0, 0, 0.05);
      }
    }
  }

  &.modal-body-flex {
    .custom-modal-body {
      display: flex;
    }
  }

  &.add-user-modal {
    .custom-modal {
      min-height: auto;
    }
  }
}

@media only screen and (max-width: $breakpoint-medium) {
  .custom-modal-wrapper {
    padding: 1rem;
    display: block;
    .custom-modal {
      min-width: 100%;
    }
  }
}
