@import '../../assets/styles/theme';

.viewContainer {
  width: 100%;
  height: 100vh;
  display: flex;

  .infoContainer {
    flex: 1.618;
    background: url("../../assets/images/auth_bg.jpg") no-repeat center;
    background-size: cover;
  }

  .authContainer {
    flex: 1;
    background: rgba(50, 125, 168,0.05);
    display: flex;
    flex-direction: column;
    align-items: center;

    .logoContainer {
      width: 15rem;
      height: 15rem;
      margin: 5rem 0;
      background: url("../../assets/images/polski_zwiazek_judo_logo.png") no-repeat center;
      background-size: contain;
    }
  }
}

@media (max-width: $breakpoint-medium) {
  .infoContainer {
    display: none;
  }
}
