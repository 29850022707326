.fatigue-profile-calendar-modal {
  .custom-modal {
    .custom-modal-close {
      top: 1rem;
      right: 1rem;
    }
  }
  .fatigue-profile-table-wrapper {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;

    .rv-radar-chart svg {
      overflow: visible;
    }

    table {
      width: 90vw;
      table-layout: fixed;
    }

    .name-column {
      width: 15rem;
    }

    th,
    td {
      padding: .25rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      vertical-align: middle;
      font-size: $xxs-font-size;
    }

    th {
      background: $table-head-background-color;
      text-align: center;
      font-weight: bold;
      color: $base-text-color;
      border: thin solid $fatigue-profile-table-border;
      padding: 0.5rem;
    }

    td {
      border: thin solid $fatigue-profile-table-border;
      max-width: 4rem;
      min-width: 4rem;
      height: 4rem;
      text-align: center;
      &:hover {
        background: $light-gray;
      }
    }

    .dummy-cell {
      border: none;
      background-color: $white-color;
    }

    .input-name {
      font-size: $xxs-font-size;
      text-transform: uppercase;
      text-align: left;
      font-weight: bold;
      background-color: inherit;
      padding-top: 1rem;
      padding-bottom: 1rem;
    }

    .fatigue-profile-group-name {
      white-space: nowrap;
      padding: 1.5rem;
      font-size: $xxs-font-size;
      text-transform: uppercase;
      font-weight: bold;
      position: relative;
      background-color: inherit;

      .collapse-button {
        background-color: transparent;
        &.horizontal {
            position: absolute;
            left: 1rem;
        }
        &.vertical {
            transform: rotate(180deg);
            padding: 0.5rem 0;
        }
      }
      .group-name-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.3rem;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        writing-mode: vertical-rl;
        text-orientation: sideways;
        transform: rotate(180deg);
        &.icon {
          transform: none;
          text-orientation: unset;
        }
        .group-name {
          display: block;
          overflow-wrap: break-word;
          height: 100%;
          width: 100%;
          white-space: pre-wrap;
        }
      }
    }
  }
  .fatigue-profile-chart-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
}
.tooltip {
  z-index: 1050;
  width: unset;
}

@media only screen and (max-width: $breakpoint-medium) {

  .fatigue-profile-calendar-modal {
    .fatigue-profile-table-wrapper {
      table {
        width: unset;
      }
    }
  }
}