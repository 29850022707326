table.data-table {
  border-collapse: collapse;
  width: 100%;

  &.fatigue-profile-list {
    tbody {
      tr {
        td {
          padding: 1rem;
        }
      }
    }
  }
  &.no-hover {
    tbody {
      tr {
        &:hover {
          &:nth-child(2n) {
            background: $table-row-background-color;
          }
          &:nth-child(2n+1) {
            background: initial;
          }
        }
      }
    }
  }

  thead{
    tr {
      background: $table-head-background-color;
      border-top: 0.1rem solid $table-border-color;
      border-bottom: 0.1rem solid $table-border-color;
      th {
        padding: 1rem;
        min-width: 8rem;
        font-weight: 600;
        text-transform: uppercase;
        font-size: $xs-font-size;
      }
    }
  }

  tbody {
    tr {
      border-bottom: 0.1rem solid $table-border-color;

      &:nth-child(2n),
      &:hover {
        background: $table-row-background-color;
      }

      td {
        vertical-align: middle;
        padding: 0.5rem 1rem;

        a {
          &:hover {
            text-decoration: none;
            font-weight: 500;
            color: $theme-color;
          }
        }
      }
    }
  }
}
