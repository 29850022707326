$btnBorderThickness: 0.1rem;
$btnBoxShadow: 0 0 0 0.1rem;

.btn-pair {
    display: inline-flex;
    .btn {
        &.btn-theme {
            border: $btnBorderThickness solid darken($theme-color, 10%);
        }
    }
    :nth-child(odd) {
        border-radius: $border-radius  0 0 $border-radius;
    }
    :nth-child(even) {
        border-radius: 0 $border-radius $border-radius 0;
    }
}
.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  cursor: pointer;
  background-color: transparent;
  text-decoration: none;
  border: $btnBorderThickness solid transparent;
  padding: 0.5rem 1.5rem;
  font-size: $base-font-size;
  line-height: 1.5;
  border-radius: $border-radius;
  min-height: $button-height;
  transition: all 0.25s ease-in-out;
  white-space: nowrap;

  span, i {
    padding: 0 0.5rem;


    & ~ i {
      padding-right: 0;
    }
  }

  &:not(:disabled):not(.disabled) {
    cursor: pointer;
  }

  &:visited {
    text-decoration: none;
  }

  &.delete {
    span {
      color: $warning-color;
    }
  }

  &.archive {
    span {
      color: $info-color;
    }
  }
  &.btn-theme {
    color: $white-color;
    background: $theme-color;
    border: $btnBorderThickness solid $theme-color;

    &:hover,
    &:focus,
    &:active,
    &:hover:focus,
    &:hover:active,
    &:focus:active,
    &:hover:focus:active,
    &:not(:disabled):not(.disabled):active{
      color: $white-color;
      background: darken($theme-color, 5%);
      border: $btnBorderThickness solid darken($theme-color, 5%);
      box-shadow: $btnBoxShadow lighten($theme-color, 5%);
    }
  }

  &.btn-theme-outline {
    color: $theme-color;
    border: $btnBorderThickness solid $theme-color;

    &:hover,
    &:focus,
    &:active,
    &:hover:focus,
    &:hover:active,
    &:focus:active,
    &:hover:focus:active {
      color: darken($theme-color, 5%);
      border: $btnBorderThickness solid darken($theme-color, 5%);
      box-shadow: $btnBoxShadow darken($theme-color, 5%);
    }
  }

  //&.btn-primary {
  //  color: $white-color;
  //  background: $primary-color;
  //  border: $btnBorderThickness solid $primary-color;
  //
  //  &:hover,
  //  &:focus,
  //  &:active,
  //  &:hover:focus,
  //  &:hover:active,
  //  &:focus:active,
  //  &:hover:focus:active,
  //  &:not(:disabled):not(.disabled):active{
  //    color: $white-color;
  //    background: darken($primary-color, 5%);
  //    border: $btnBorderThickness solid darken($primary-color, 5%);
  //    box-shadow: $btnBoxShadow darken($primary-color, 5%);
  //  }
  //}

  &.btn-primary-outline {
    color: $primary-color;
    border: $btnBorderThickness solid $primary-color;

    &:hover,
    &:focus,
    &:active,
    &:hover:focus,
    &:hover:active,
    &:focus:active,
    &:hover:focus:active {
      color: darken($primary-color, 5%);
      border: $btnBorderThickness solid $primary-color;
      box-shadow: $btnBoxShadow darken($primary-color, 5%);
    }
  }

  &.btn-danger {
    color: $white-color;
    background: $danger-color;
    border: $btnBorderThickness solid $danger-color;

    &:hover,
    &:focus,
    &:active,
    &:hover:focus,
    &:hover:active,
    &:focus:active,
    &:hover:focus:active,
    &:not(:disabled):not(.disabled):active{
      color: $white-color;
      background: darken($danger-color, 5%);
      border: $btnBorderThickness solid darken($danger-color, 5%);
      box-shadow: $btnBoxShadow darken($danger-color, 5%);
    }
  }

  &.btn-danger-outline {
    color: $danger-color;
    border: $btnBorderThickness solid $danger-color;

    &:hover,
    &:focus,
    &:active,
    &:hover:focus,
    &:hover:active,
    &:focus:active,
    &:hover:focus:active {
      color: darken($danger-color, 5%);
      border: $btnBorderThickness solid darken($danger-color, 5%);
      box-shadow: $btnBoxShadow darken($danger-color, 5%);
    }
  }

  &.btn-info {
    color: $white-color;
    background: $info-color;
    border: $btnBorderThickness solid $info-color;

    &:hover,
    &:focus,
    &:active,
    &:hover:focus,
    &:hover:active,
    &:focus:active,
    &:hover:focus:active,
    &:not(:disabled):not(.disabled):active{
      color: $white-color;
      background: darken($info-color, 5%);
      border: $btnBorderThickness solid darken($info-color, 5%);
      box-shadow: $btnBoxShadow darken($info-color, 5%);
    }
  }

  &.btn-info-outline {
    color: $info-color;
    border: $btnBorderThickness solid $info-color;

    &:hover,
    &:focus,
    &:active,
    &:hover:focus,
    &:hover:active,
    &:focus:active,
    &:hover:focus:active {
      color: darken($info-color, 5%);
      border: $btnBorderThickness solid darken($info-color, 5%);
      box-shadow: $btnBoxShadow darken($info-color, 5%);
    }
  }

  &.btn-success {
    color: $white-color;
    background: $success-color;
    border: $btnBorderThickness solid $success-color;

    &:hover,
    &:focus,
    &:active,
    &:hover:focus,
    &:hover:active,
    &:focus:active,
    &:hover:focus:active,
    &:not(:disabled):not(.disabled):active{
      color: $white-color;
      background: darken($success-color, 5%);
      border: $btnBorderThickness solid darken($success-color, 5%);
      box-shadow: $btnBoxShadow darken($success-color, 5%);
    }
  }

  &.btn-success-outline {
    color: $success-color;
    border: $btnBorderThickness solid $success-color;

    &:hover,
    &:focus,
    &:active,
    &:hover:focus,
    &:hover:active,
    &:focus:active,
    &:hover:focus:active {
      color: darken($success-color, 5%);
      border: $btnBorderThickness solid darken($success-color, 5%);
      box-shadow: $btnBoxShadow darken($success-color, 5%);
    }
  }

  &.btn-warning {
    color: $white-color;
    background: $warning-color;
    border: $btnBorderThickness solid $warning-color;

    &:hover,
    &:focus,
    &:active,
    &:hover:focus,
    &:hover:active,
    &:focus:active,
    &:hover:focus:active,
    &:not(:disabled):not(.disabled):active{
      color: $white-color;
      background: darken($warning-color, 5%);
      border: $btnBorderThickness solid darken($warning-color, 5%);
      box-shadow: $btnBoxShadow darken($warning-color, 5%);
    }
  }

  &.btn-warning-outline {
    color: $warning-color;
    border: $btnBorderThickness solid $warning-color;

    &:hover,
    &:focus,
    &:active,
    &:hover:focus,
    &:hover:active,
    &:focus:active,
    &:hover:focus:active {
      color: darken($warning-color, 5%);
      border: $btnBorderThickness solid darken($warning-color, 5%);
      box-shadow: $btnBoxShadow darken($warning-color, 5%);
    }
  }

  &.disabled,
  &:disabled {
    opacity: 0.25;
    cursor: not-allowed;
  }

  &.btn-small {
    min-height: 2.75rem;
    padding: 0.5rem 1rem;
    font-size: $xxs-font-size;
    letter-spacing: 0.1rem;
    text-transform: uppercase;
    &.btn-add-item {
      padding: 0.5rem 0;
    }
    &.big-font {
      font-size: $base-font-size;
      padding: 0.5rem;
    }
  }

  &.btn-medium {
    font-size: $xs-font-size;
    text-transform: uppercase;
  }

  &.btn-list {
    min-height: unset;
    padding: 0.5rem;
    font-size: $s-font-size;
    color: $theme-color;
    border: 0.1rem solid $list-button-background-color;
    background: $list-button-background-color;
    transition: all 0.25s ease-in-out;

    &.archive {
      color: $danger-color;
      &:hover,
      &:focus,
      &:active,
      &:hover:focus,
      &:hover:active,
      &:focus:active,
      &:hover:focus:active {
        color: darken($danger-color, 5%);
        background-color: darken($list-button-background-color, 2%);
        border: $btnBorderThickness solid darken($danger-color, 2%);
      }

      span {
        color: $danger-color;
      }
    }
    &.restore {
      color: $info-color;
    }
    &.fatigue-profile {
      height: 4rem;
      width: 4rem;
      font-size: $base-font-size;
      &.delete {
        span {
          color: $warning-color;
        }
      }
    }

    &:hover {
      background: rgba(50, 125, 168,0.1);
    }
  }
}

.btn-close {
  position: absolute;
  top: -0.5rem;
  right: -0.75rem;
  width: 3rem;
  height: 3rem;
  background: none;
  opacity: 0.5;
  border-radius: 50%;
  transition: all 0.25s ease-in-out;
  color: $theme-color;
  font-size: $s-font-size;

  &:hover {
    background: rgba(50, 125, 168, 0.05);
    opacity: 1;
  }
  &.text {
      border: $btnBorderThickness solid $theme-color;
      width: unset;
      right: 0;
      border-radius: $border-radius;
      padding: 0.5rem 1.5rem;
  }
}


.btn-fatigue-profile {
  color: $theme-color;
  border: $btnBorderThickness solid $table-border-color;
  background-color: $fatigue-profile-button-background;
  &:hover,
  &:focus,
  &:active,
  &:hover:focus,
  &:hover:active,
  &:focus:active,
  &:hover:focus:active {
    color: darken($theme-color, 5%);
    background-color: darken($fatigue-profile-button-background, 2%);
    border: $btnBorderThickness solid darken($table-border-color, 2%);
  }
}

