
.toast {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  display: flex;
  align-items: center;
  width: 32rem;
  min-height: 8rem;
  z-index: 1041;
  padding: 2rem 5rem 2rem 2rem;
  background-color: #f0f0f0;
  border-radius: $border-radius;
  border: 0;
  font-size: 1.4rem;
  font-family: "Ubuntu", sans-serif;
  color: $white-color;
  opacity: 1;

  .toast-message {
    font-size: 1.4rem;
    word-break: break-word;
  }

  .btn-toast-close {
    position: absolute;
    width: 4rem;
    height: 4rem;
    top: calc(50% - 2rem);
    right: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border-radius: 50%;
    cursor: pointer;

    &:hover {
      span {
        opacity: 1;
      }
    }

    span {
      width: 1rem;
      height: 1rem;
      position: absolute;
      opacity: 0.75;
      background:
              linear-gradient(45deg, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 43%,#fff 45%,#fff 55%,rgba(0,0,0,0) 57%,rgba(0,0,0,0) 100%),
              linear-gradient(135deg, transparent 0%,transparent 43%,#fff 45%,#fff 55%,transparent 57%,transparent 100%);
    }
  }

  &.toast-theme {
    background-color: $theme-color;

    .btn-toast-close {
      &:hover {
        background-color: darken($theme-color, 3%);
      }
    }
  }

  &.toast-success {
    color: $success-color;

    .btn-toast-close {
      &:before {
        background-color: $success-color;
      }
    }
  }

  &.toast-danger {
    color: $danger-color;

    .btn-toast-close {
      &:before {
        background-color: $danger-color;
      }
    }
  }

  &.toast-warning {
    color: $warning-color;

    .btn-toast-close {
      &:before {
        background-color: $warning-color;
      }
    }
  }

  &.toast-info {
    color: $info-color;

    .btn-toast-close {
      &:before {
        background-color: $info-color;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .toast {
    width: unset;
    max-width: unset;
    left: 2rem;
  }
}
