.custom-card {
  margin-bottom: 2rem;
  font-family: $secondary-font-family;
  box-shadow: 0 0.2rem 1.5rem rgba(0, 0, 0, 0.05);
  border-radius: $border-radius;
  display: flex;
  flex-direction: column;
  background-color: $white-color;
  position: relative;

  &.flex {
    display: flex;
    flex: 1;
  }

  &.daily-form {
    height: 100%;
    .react-datepicker__week {
      display: flex;
      justify-content: space-around;
      .react-datepicker__day {
        display: flex;
        flex: 1;
      }
    }
    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
      font-size: $l-font-size;
    }
  }

  &.account {
    .custom-card-body {
      padding: 2rem 3rem 3rem 3rem;
    }

    .custom-card-header {
      .custom-card-title {
        border-bottom: thin solid $table-border-color;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 1rem;
      }
    }
  }

  &.auth {
    margin: 0 auto;
    max-width: 45rem;
    width: 100%;
    font-size: 1.6rem;
  }

  &.view-upload {
    height: 6.75rem;
  }

  .custom-card-header {
    padding: 3rem 3rem 0 3rem;
  }

  .custom-card-body {
    padding: 2rem 3rem;
    flex: 1;
  }

  .custom-card-footer {
  }
}

@media only screen and (max-width: $breakpoint-medium) {
  .custom-card {
    margin-bottom: 2rem;

    .custom-card-header {
      padding: 2rem 2rem 0 2rem;
    }

    .custom-card-body {
      padding: 2rem;
    }
  }
}

